import React from 'react'
import Map from "./Map";
import Contact from "./ContactForm";
import Social from "./Social";
import Modal from "react-modal";

Modal.setAppElement("#root");

export default function ContactPopUp({isOpen, toggleModal}) {
  return (

    <Modal
      isOpen={isOpen}
      onRequestClose={toggleModal}
      contentLabel="My dialog"
      className="custom-modal"
      overlayClassName="custom-overlay"
      closeTimeoutMS={500}
    >
       <div className="kb_mobalbox_contact">
         <button className="close-modal" onClick={toggleModal}>
           <img src="/img/svg/cancel.svg" alt="close icon" />
         </button>
         {/* End close icon */}
         <div className="box_inner">
           <div className="description_wrap scrollable">
             {/* Start modal content */}
             <div className="title">
               <h3>Ä Nachricht schickä</h3>
             </div>
             {/* End title */}

             <div className="wrapper">
               <div className="left">
                 <div className="fields">
                   <Contact />
                 </div>
               </div>
               {/* End left */}
               <div className="right">
                 <div className="map_wrap desktop_map">
                   <Map />
                 </div>
               </div>
               {/* End right */}
             </div>
             {/* End wrapper */}

             <div className="short_info">
               <ul>
                 <li>
                   <a href="tel:+41 76 392 99 53">
                     <div className="list_inner">
                       <img
                         className="svg"
                         src="img/svg/phone.png"
                         alt="phone"
                       />
                       <p>
                         +41 76 392 99 53
                       </p>
                     </div>
                   </a>
                 </li>

                 <li>
                   <div className="list_inner">
                     <Social />
                   </div>
                 </li>

                 <li>
                   <a href='https://maps.app.goo.gl/Dw5SBK8WvJ3Qm8is5'>
                    <div className="list_inner">
                        <img
                        className="svg"
                        src="img/svg/location.png"
                        alt="location"
                        />
                        <p>3018 Bern</p>
                    </div>
                   </a>
                 </li>
               </ul>
             </div>
             {/* End modal content */}
           </div>
         </div>
         {/* End box inner */}
       </div>
       {/* End modalbox news */}
     </Modal>
  )
}
