import React, { useState, useEffect } from "react";

import { useQuery} from '@apollo/client';
import { GET_BLOGPOSTS } from '../../backend/api/queries'
import { cleanPostsData } from '../../backend/api/api-functions'
import BlogBox from "./BlogBox";
import BlogSkeleton from "./BlogSkeleton";

const News = () => {

  const [blogPosts, setBlogPosts] = useState(null)
  const { loading, error, data } = useQuery(GET_BLOGPOSTS);

  useEffect(()=>{

    if (data && blogPosts === null && !loading && !error){
      var sortedBlogPosts = cleanPostsData(data, "blogpost");
      setBlogPosts(sortedBlogPosts)
      console.log(sortedBlogPosts)
    }

  }, [data, blogPosts])

  return (
    <section className="kb_section" id="blog">
      <div className="kb_news">
        <div className="container">
          <div className="kb_title">
            <span>Blog</span>
            <h3>Nöii Biiträg</h3>
          </div>
          {/* End kb_title */}
          <div className="news_list">
            <ul>
              


            { loading ? 
            <>
              <BlogSkeleton />
              <BlogSkeleton />
              <BlogSkeleton />
            </>
            :

              blogPosts ? 
                blogPosts.map((post)=>
                  <BlogBox data={post} />
                )
              :
                <p>Keni News</p>
            
            }

            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default News;
