import React, { useState, useEffect } from "react";
import Scrollspy from "react-scrollspy";
import Social from "../Contact/Social";
import { NavLink, useLocation } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

const Header = ({ stayWhite }) => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const [navbarWhite, setNavbarWhite] = useState(false);
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbarWhite(true);
    } else if (!stayWhite) {
      setNavbarWhite(false);
    }
  };

  useEffect(() => {
    if (stayWhite) {
      setNavbarWhite(true);
    } else {
      window.addEventListener("scroll", changeBackground);
      // Entferne den Event-Listener bei der Demontage
      return () => window.removeEventListener("scroll", changeBackground);
    }
  }, [stayWhite]);

  const handleNavLinkClick = (id) => {
    // Scroll to the target ID after a short delay to ensure the page has loaded
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 100);

    // Close the mobile menu after clicking
    setClick(false);
  };

  return (
    <>
      <header className="kb_topbar">
        <div className={navbarWhite ? "topbar_inner opened" : "topbar_inner"}>
          <div className="logo">
            <NavLink to="/">
              <img src="/img/logo/kbLongBlack.png" alt="Kevin Bieri Logo" />
            </NavLink>
          </div>
          {/* End logo */}
          <ul className="menu">
            <Scrollspy
              className="anchor_nav"
              items={["home", "about", "portfolio", "contact"]}
              currentClassName="current"
              offset={-200}
            >
              <li>
                <NavLink exact to="/" activeClassName="current">
                  Home
                </NavLink>
              </li>
              {isHomePage ? (
                <>
                  <li>
                    <ScrollLink to="about" smooth={true} offset={-200} duration={500} activeClass="current">
                      About
                    </ScrollLink>
                  </li>
                  <li>
                    <ScrollLink to="portfolio" smooth={true} offset={-200} duration={500} activeClass="current">
                      Portfolio
                    </ScrollLink>
                  </li>
                  <li>
                    <ScrollLink to="contact" smooth={true} offset={-200} duration={500} activeClass="current">
                      Kontakt
                    </ScrollLink>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <NavLink to="/" onClick={() => handleNavLinkClick('about')}>
                      About
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/" onClick={() => handleNavLinkClick('portfolio')}>
                      Portfolio
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/" onClick={() => handleNavLinkClick('contact')}>
                      Kontakt
                    </NavLink>
                  </li>
                </>
              )}
              <li>
                <NavLink to="/blog" activeClassName="current">
                  Blog
                </NavLink>
              </li>
            </Scrollspy>
          </ul>
          {/* End menu */}
        </div>
      </header>
      {/* End kb_topbar */}

      {/* Start kb mobile menu */}
      <aside className="kb_mobile_menu">
        <div className="topbar_inner">
          <div className="container bigger">
            <div className="topbar_in">
              <div className="logo">
                <NavLink to="/">
                  <img src="/img/logo/kbLongBlack.png" alt="Kevin Bieri Logo" />
                </NavLink>
              </div>
              {/* End logo */}
              <div className="my_trigger" onClick={handleClick}>
                <div
                  className={
                    click
                      ? "hamburger hamburger--collapse-r is-active"
                      : "hamburger"
                  }
                >
                  <div className="hamburger-box">
                    <div className="hamburger-inner"></div>
                  </div>
                </div>
                {/* End hamburger menu */}
              </div>
            </div>
          </div>
        </div>
        <div className={click ? "dropdown active" : "dropdown"}>
          <div className="container">
            <span className="close_menu" onClick={handleClick}>
              X
            </span>
            <nav className="dropdown_inner">
              <ul className="anchor_nav">
                <li className="current">
                  <a href="#home" onClick={() => handleNavLinkClick('home')}>
                    Home
                  </a>
                </li>
                <li>
                  <a href="#about" onClick={() => handleNavLinkClick('about')}>
                    About
                  </a>
                </li>
                <li>
                  <a href="#portfolio" onClick={() => handleNavLinkClick('portfolio')}>
                    Projekte
                  </a>
                </li>
                <li>
                  <a href="#blog" onClick={() => handleNavLinkClick('blog')}>
                    Blog
                  </a>
                </li>
                <li>
                  <a href="#contact" onClick={() => handleNavLinkClick('contact')}>
                    Kontakt
                  </a>
                </li>
              </ul>
              <div className="social-menu">
                <Social />
              </div>
              {/* End social share */}
            </nav>
          </div>
          {/* End container */}
        </div>
      </aside>
      {/* End kb mobile menu */}
    </>
  );
};

export default Header;
